html,
body {
    height: 100%;
    margin: 0;
}

.App {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    position: sticky;
    top: 0;
}

.App-footer {
    background-color: #282c34;
}

.App-body {
    overflow: auto;
    padding: 100px 5px 5px 5px;
    background: #12161a;
    color: white;
    height: 100vh;
    font-size: x-large;
    justify-content: center;

    .title {
        font-size: xx-large;
    }
}

.MuiAppBar-root {
    box-shadow: none !important;
}

.font-face-special-elite {
    font-family: 'SpecialElite-Regular';
}

.MuiMenu-list {
    background-color: #12161a;
    color: white;
}

.icons {
    color: white;
}
